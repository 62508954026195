<template>
  <div class="cute-list">
    <slot name="button" slot="button"></slot>
    <el-table
      v-if="!treeFlag"
      :data="tableData"
      @selection-change="selectionChange"
      @expand-change="expandChange"
      @row-click="rowClick"
      :stripe="stripe"
      v-loading="loading"
      :lazy="lazy"
      ref="table"
      :row-class-name="tableRowClassName"
      style="width: 100%"
      :show-header="showHeader"
      :cell-class-name="cellClassName"
      :span-method="arraySpanMethod"
    >
      <slot></slot>
    </el-table>
    <el-table
      v-if="treeFlag"
      :data="tableData"
      @selection-change="selectionChange"
      @expand-change="expandChange"
      @row-click="rowClick"
      :stripe="stripe"
      v-loading="loading"
      :lazy="lazy"
      :tree-props="treeProps"
      :row-key="getRowKeys"
      :expand-row-keys="expandRowKeys"
      :row-class-name="tableRowClassName"
      style="width: 100%"
      :span-method="arraySpanMethod"
    >
      <slot></slot>
    </el-table>
    <el-pagination
      v-if="pagination"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageNumber"
      :page-sizes="pageSizeOption"
      :page-size="pageSize"
      layout="total, prev, pager, next"
      :total="tableTotal"
    >
    </el-pagination>
    <div v-if="Teu" class="teu">Containers {{ Teu }} TEU</div>
  </div>
</template>
<script>
export default {
  props: {
    treeFlag: { type: Boolean, default: false },
    source: { type: String, default: "server" }, //数据源，server | local
    initDataFlag: { type: Boolean, default: true }, //默认是否请求一次数据
    pagination: { type: Boolean, default: true }, //是否带分页
    //---------------------- if source === server --------------------------------//
    url: { type: String }, //后台地址
    method: { type: String, default: "post" }, //请求方式
    queryParams: {
      type: Object,
      default: function () {
        return {};
      },
    }, //请求参数
    //---------------------- if source === local  --------------------------------//
    defaultData: {
      type: Array,
      default: function () {
        return [];
      },
    },
    defaultPageSize: { type: Number, default: 10 }, //每页条数
    defaultPageNumber: { type: Number, default: 1 }, //当前页数
    pageSizeOption: {
      type: Array,
      default: function () {
        return [10, 20, 30, 100];
      },
    }, //可选每页条数
    // loading: { type: Boolean, default: false },
    lazy: { type: Boolean, default: false },
    treeProps: {
      type: Object,
      default: function () {
        return {};
      },
    },
    getRowKeys: {
      type: Function,
    },
    expandRowKeys: {
      type: Array,
      default: function () {
        return [];
      },
    },
    stripe: { type: Boolean, default: false },
    // tableRowClassName: { type: String },
    showHeader: { type: Boolean, default: true },
    cellClassName: { type: String },
    Teu: { type: String, default: "" },
  },
  data() {
    let params = {
      serverData: { rows: [], total: 0 },
      pageSize: this.defaultPageSize,
      pageNumber: this.defaultPageNumber,
      checkedData: [],
      loading: false,
    };
    return params;
  },
  computed: {
    tableData() {
      if (this.source === "server") {
        return this.serverData.rows;
      } else {
        return this.defaultData;
      }
    },
    tableTotal() {
      if (this.source === "server") {
        return this.serverData.total;
      } else {
        return this.tableData.length;
      }
    },
  },
  methods: {
    handleSizeChange: function (size) {
      this.pageSize = size;
      this.load();
      this.$emit("handleSizeChange", size);
    },
    expandChange: function (row, expandedRows) {
      this.$emit("expand-change", row, expandedRows);
    },
    selectionChange: function (currentRow, oldCurrentRow) {
      this.$emit("selection-change", currentRow, oldCurrentRow);
    },
    rowClick: function (row, expandedRows) {
      this.$emit("row-click", row, expandedRows);
    },
    arraySpanMethod: function ({ row, column, rowIndex, columnIndex }) {
      this.$emit("arraySpanMethod", { row, column, rowIndex, columnIndex });
    },
    // tableRowClassName: function ({ row, rowIndex }) {
    //   console.log(row,rowIndex)
    //   this.$emit("tableRowClassName", row);
    // },
    // 根据返回的数据满足过滤条件行高亮显示
    tableRowClassName: function ({ row, rowIndex }) {
      let data = "";
      this.$emit(
        "row-class-name",
        {
          row: row,
          rowIndex: rowIndex,
        },
        (val) => {
          data = val;
        }
      );
      return data; //属性名必须返回一个string
    },

    handleCurrentChange: function (index) {
      this.pageNumber = index;
      this.load();
      this.$emit("handleCurrentChange", index);
    },
    toggleRowSelection: function (row, flag) {
      this.$refs.table.toggleRowSelection(row, flag);
    },
    query: function () {
      if (this.queryParams.startPage) {
        this.pageNumber = this.queryParams.startPage;
      } else {
        this.pageNumber = this.defaultPageNumber;
      }
      if (this.queryParams.pageSize) {
        this.pageSize = this.queryParams.pageSize;
      } else {
        this.pageSize = this.defaultPageSize;
      }
      this.load();
    },
    getListData() {
      return this.serverData;
    },
    load: function () {
      if (this.source === "server") {
        let url = this.url;
        this.loading = true;
        if (this.pagination) {
          this.queryParams.pageNo = this.pageNumber;
          this.queryParams.pageSize = this.pageSize;
        }
        this.$ajax
          .cPost(url, this.queryParams)
          .then((res) => {
            this.loading = false;
            if (res.data.result) {
              if (res.data.list) {
                this.serverData.rows = res.data.list;
              } else {
                this.serverData.rows = [];
              }
              if (this.treeProps.hasChildren) {
                this.serverData.rows.forEach((item) => {
                  item.hasChildren = true;
                });
              }
              this.serverData.total = res.data.allCount;
              this.$emit("onloadSuccess", this.serverData);
            }
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
    childLoad: function (tree, treeNode, resolve) {
      this.$emit("childLoad", tree, treeNode, resolve);
    },
  },
  created: function () {
    if (this.initDataFlag) {
      this.query();
    }
  },
};
</script>
<style lang="scss">
@import "../../assets/base.scss";
.el-table {
  border: 2px solid $borderColor1;
  color: $mainColor !important;
}
.el-table th.el-table__cell {
  background-color: $background1 !important;
}
.el-table--fit {
  border-right: 1px solid $borderColor1 !important;
}

.el-table__cell .samll-list-primary {
  height: 40px;
  background-color: $mainColor !important;
  color: #fff !important;
  border: 2px solid #132569;
  font-family: "montserratregular";
  margin: 0 3px;
  font-size: 14px;
  padding: 0 15px !important;
  border-radius: 0;
}

.el-table__cell .samll-list-info:hover,
.samll-list-primary:hover {
  background-color: $background2 !important;
  color: #fff !important;
  border: 2px solid $background2 !important;
}

.el-table__cell .is-disabled {
  background-color: $borderColor1 !important;
  border: 2px solid $fontColor1 !important;
}

.el-table__cell .is-disabled:hover {
  background-color: $borderColor1 !important;
  border: 2px solid $fontColor1 !important;
}

.hover-row .el-table__cell {
  background-color: $background1 !important;
}

.el-table__cell .samll-list-info {
  height: 40px;
  background-color: #fff;
  color: $mainColor;
  border: 2px solid #132569;
  font-family: "montserratregular";
  margin: 0 3px;
  padding: 0 15px !important;
  border-radius: 0;
}

.el-table__cell .chat-icon {
  height: 40px !important;
  width: 40px !important;
  background-color: #fff;
  color: $mainColor;
  background: $background2;
  padding: 0 !important;
  border-radius: 0;
  .svg-icon {
    height: 20px;
    width: 20px;
    color: $background2;
    fill: #fff;
    stroke: #fff;
  }
}

.el-pagination {
  border-top: 4px solid $mainColor;
  margin-top: 20px;
  padding-top: 20px !important;
  .el-pagination__total {
    font-size: 20px !important;
    color: $mainColor;
    display: block;
    float: left;
  }
  .el-pager {
    font-size: 20px !important;
    .number {
      font-size: 20px !important;
      color: $fontColor1;
    }
    .active {
      color: $mainColor;
    }
  }
}

.el-pagination .btn-next .el-icon,
.el-pagination .btn-prev .el-icon {
  font-size: 30px !important;
  color: $fontColor1;
}
.el-pagination .el-icon:hover {
  color: $mainColor !important;
}
.el-pagination button:disabled .el-icon {
  color: $fontColor1 !important;
}
.cute-list {
  position: relative;
}
.teu {
  position: absolute;
  bottom: 6px;
  left: 100px;
  font-size: 20px;
  color: $mainColor;
}

.moreList {
  .el-button {
    color: #132569;
  }
  .is-disabled {
    color: $fontColor1;
  }
}
</style>