<template>
  <div class="companyAddress" v-loading="loading">
    <div class="addressTitle">{{ position=='Company'?$i18n.locale == 'zh_cn'?'公司':'Company':$i18n.locale == 'zh_cn'?'合作伙伴':'Partner' }} {{ $t("lang.SText3811") }}</div>
    <div class="search">
      <div class="form-input">
        <div class="form-input-title">{{ $t("lang.SText39") }}</div>
        <el-cascader
          :options="tagSelectOption"
          :props="props"
          collapse-tags
          class="cascader"
          :placeholder="$t('lang.BText80')"
          :show-all-levels="false"
          @visible-change="visibleChange"
          v-model="addressTagList"
          @remove-tag="removeTag"
          ><template slot-scope="{ node, data }">
            <div class="line1">{{ data.label }}</div>
            <div v-if="data.email" class="line2">{{ data.email }}</div>
          </template>
        </el-cascader>
      </div>
      <!-- <div class="form-input">
        <div class="form-input-title">Address tag</div>
        <el-select v-model="chooseData.partnerId" filterable placeholder="">
          <el-option
            v-for="item in partnerList"
            :key="item.partnerId"
            :label="item.name"
            :value="item.partnerId"
          >
          </el-option>
        </el-select>
      </div> -->
      <dw-button
        @click="newAddress"
        class="right-button"
        type="addbutton"
        isAdd
        >{{ $t("lang.SText40") }}</dw-button
      >
    </div>
    <dwlist
      :url="source.address_api.addressList"
      :query-params="queryForm"
      ref="addressList"
      :initDataFlag="false"
    >
      <el-table-column prop="address" :label="AddressLabel" width="400px">
        <template slot-scope="scope">
          <div class="name" v-if="scope.row.partnerName">
            <div class="item">{{ scope.row.partnerName }}</div>
          </div>
          <div class="name">
            <div class="item" v-if="scope.row.name">{{ scope.row.name }}</div>
            <div
              class="item"
              style="color: #999999"
              v-if="scope.row.phone && scope.row.phone != 'undefined undefined'"
            >
              {{ scope.row.phone }}
            </div>
            <div class="item partnerTag" v-if="scope.row.role != ''">
              {{ scope.row.role }}
            </div>
          </div>
          <div class="address">
            <span v-show="scope.row.address">{{ scope.row.address }},</span>
            <span v-show="scope.row.cityShow">{{ scope.row.cityShow }},</span>
            <span v-show="scope.row.stateShow">{{ scope.row.stateShow }},</span>
            <span v-show="scope.row.countryOrRegionShow">{{
              scope.row.countryOrRegionShow
            }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="tag" :label="$t('lang.SText39')"
        ><template slot-scope="scope">
          <div class="RoleRight">
            <div class="tagList">
              <div
                class="tag"
                v-for="(item, index) in JSON.parse(scope.row.addressTag).System"
                :key="index"
              >
                {{ item.name }}
              </div>
              <div
                class="tag"
                v-for="(item, index) in JSON.parse(scope.row.addressTag)
                  .Customized"
                :key="index"
              >
                {{ item.name }}
              </div>
            </div>
            <div
              class="signOut-icon1"
              :class="{ 'signOut-icon': scope.row.signOut }"
              @mouseover="signOutHover(scope.row)"
              @mouseleave="signOutHover(scope.row, 1)"
            >
              ···
            </div>
            <div
              v-show="scope.row.signOut"
              class="signOutHover"
              @mouseleave="signOutHover(scope.row, 1)"
              @mouseover="signOutHover(scope.row)"
            >
              <div class="list-button">
                <div
                  class="list-button-item"
                  :class="{ unClick: scope.row.lockState == '1' }"
                  @click="editAddress(scope.row)"
                >
                  {{ $t("lang.QText77") }}
                </div>
                <!-- companyDefault: 1公司默认地址, 2发票地址,0普通添加地址 -->
                <div
                  class="list-button-item"
                  @click="openCheckBox(scope.row)"
                  :class="{ unClick: scope.row.lockState == '1' }"
                  v-if="scope.row.companyDefault == '0'"
                >
                  | {{ $t("lang.BText40") }}
                </div>
              </div>
            </div>
          </div>
        </template>
      </el-table-column>
    </dwlist>
    <el-dialog
      :visible.sync="editFlag"
      width="70%"
      :lock-scroll="false"
      :append-to-body="true"
      class="editDialog dw-dialog"
      :destroy-on-close="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div class="dialog-title1 dialog-header">
        <div class="left">
          <svg-icon icon-class="userHead" class="svg-icon"></svg-icon>
          {{ dialogTitle }}
        </div>
        <div class="close" @click="closeDialog()">
          <svg-icon class="close-icon" icon-class="close"></svg-icon>
        </div>
      </div>
      <div class="el-dialog-main" ref="dwDialogMain">
        <edit-info
          class="editInfo"
          @SaveSuccess="
            editFlag = false;
            query();
          "
          :chooseData="chooseData"
          :position="position"
          :tagSelectOption="tagSelectOption"
          @Cancel="editFlag = false"
          ref="editInfo"
        ></edit-info>
      </div>
    </el-dialog>
    <dw-check-box
      :showCheckBox="showDelect"
      @Cancel="Cancel"
      @Save="deleteAddress"
      :primaryButton="$t('lang.BText40')"
      :CancelButton="$t('lang.QText79')"
    >
      {{ $t("lang.SText41") }}
    </dw-check-box>
  </div>
</template>

<script>
import dwlist from "../../../components/dwList/dwlist.vue";
import test from "../../../test/test";
import editInfo from "./edit.vue";
import dwCheckBox from "../../../components/dwCheckBox/dwCheckBox.vue";
export default {
  props: {
    position: {
      type: String,
      default: "Company",
    },
  },
  components: {
    dwlist,
    editInfo,
    dwCheckBox,
  },
  data() {
    return {
      listData: test.addressData,
      editFlag: false,
      showDelect: false,
      listLoading: true,
      props: {
        multiple: true,
      },
      queryForm: {},
      chooseData: {},
      addressTagList: [], //searchtag
      tagListData: {
        system: [
          {
            name: "Shipper", //标签名称
            id: "Shipper", //标签id
          },
          {
            name: "Consignee", //标签名称
            id: "Consignee", //标签id
          },
          {
            name: "Office", //标签名称
            id: "Office", //标签id
          },
          {
            name: "Invoice", //标签名称
            id: "Invoice", //标签id
          },
          {
            name: "Registration", //标签名称
            id: "Registration", //标签id
          },
          {
            name: "Origin PickUp", //标签名称
            id: "Origin_PickUp", //标签id
          },
          {
            name: "Destination Delivery", //标签名称
            id: "Destination_Delivery", //标签id
          },
        ], //标签类型,标识是system标签
        customized: [
          {
            name: "Destination Delivery", //标签名称
            id: "Destination_Delivery", //标签id
          },
        ], //标签类型,标识是customized标签
      },
      loading: false,
      partnerList: [],
      userInfo: {},
      dialogTitle: "",
    };
  },
  mounted() {
    this.query();
    this.tagList();
  },
  computed: {
    tagSelectOption: function () {
      let option = [];
      let that = this;
      for (let tagType in that.tagListData) {
        let data = {
          value: tagType,
          label: tagType,
          children: [],
        };
        that.tagListData[tagType].forEach((item) => {
          let children = {
            value: item.id,
            label: item.name,
          };
          data.children.push(children);
        });
        option.push(data);
      }
      return option;
    },
    AddressLabel: function () {
      if (this.position == "Company") {
        if(this.$i18n.locale == 'zh_cn'){
          return "地址";
        }else{
          return "Address";
        }
      } else {
        if(this.$i18n.locale == 'zh_cn'){
          return "地址&合作伙伴";
        }else{
          return "Address & partner";
        }
      }
    },
  },
  created() {
    this.$session("userInfo").get((value) => {
      this.userInfo = value;
    });
  },
  methods: {
    query() {
      this.position == "Company"
        ? (this.queryForm.type = "company")
        : (this.queryForm.type = "partner");
      this.queryForm.companyId = this.userInfo.companyid;
      this.$refs.addressList.query();
    },
    tagChange() {
      if (this.addressTagList.length > 0) {
        let newAddressTag = "";
        this.addressTagList.forEach((item, index) => {
          newAddressTag = newAddressTag + item[1] + ",";
        });
        this.queryForm.addressTag = newAddressTag;
      } else {
        this.queryForm.addressTag = "";
      }
    },
    removeTag(e) {
      setTimeout(() => {
        this.tagChange();
        this.query();
      }, 20);
    },
    signOutHover(data, type) {
      if (type) {
        this.$set(data, "signOut", false);
        return;
      }
      this.$set(data, "signOut", true);
    },
    editAddress(data) {
      if (data.lockState == "1") {
        return;
      }
      let chooseData = { ...{}, ...data };
      let newString = chooseData.addressTag;
      let oldData = JSON.parse(newString);
      let newArr = [];

      for (const key in oldData) {
        oldData[key].forEach((tag, index) => {
          let item = [];
          item.push(key);
          item.push(tag.id);
          newArr.push(item);
        });
      }
      let arr = chooseData.phone.split(" ");
      chooseData.phone = arr[1];
      chooseData.dialCode = arr[0];
      chooseData.addressTag = newArr;
      this.chooseData = chooseData;
      // this.form.addressTag = JSON.parse(this.chooseData.addressTag);
      this.editFlag = true;
      this.dialogTitle = this.$t("lang.SText146");
      this.$refs.editInfo.clearValidate()
    },
    closeDialog() {
      this.editFlag = false;
    },
    newAddress() {
      this.chooseData = {};
      this.editFlag = true;
      this.dialogTitle = this.$t("lang.SText147");
      // this.$refs.editInfo.clearValidate()
      if (this.$refs["editInfo"]) {
        // 延时执行
        this.$nextTick(function () {
          this.$refs["editInfo"].clearValidate();
        });
      }
    },
    openCheckBox(data) {
      if (data.lockState == "1") {
        return;
      }
      this.chooseData = { ...{}, ...data };
      this.showDelect = true;
    },
    Cancel() {
      this.showDelect = false;
    },
    deleteAddress() {
      this.loading = true;
      this.$ajax
        .cPost(this.source.address_api.delAddress, { id: this.chooseData.id })
        .then((res) => {
          this.loading = false;
          this.showDelect = false;
          this.query();
          if (res.data.result) {
            this.$notify.success({
              title: res.data.message,
              offset: 100,
              duration: 3000,
            });
          } else {
            this.$notify.error({
              title: res.data.message,
              offset: 100,
              duration: 3000,
            });
          }
        });
    },
    tagList() {
      //返回数据
      this.$ajax
        .cPost(this.source.address_api.select, {
          companyId: this.userInfo.companyid,
        })
        .then((res) => {
          if (res.data.result == "false") {
            this.$notify.error({
              title: res.message,
              offset: 100,
              duration: 3000,
            });
          } else {
            this.tagListData = res.data.list;
          }
        });
    },
    getParterList() {
      this.$ajax
        .cPost(this.source.address_api.parterSelect, {
          companyId: this.userInfo.companyid,
        })
        .then((res) => {
          if ((res.data.result = "true")) {
            this.partnerList = res.data.data;
          } else {
            this.$notify.error({
              title: res.data.message,
              offset: 100,
              duration: 3000,
            });
          }
        });
    },
    visibleChange(e) {
      if (!e) {
        this.tagChange();
        this.query();
      }
    },
  },
};
</script>

<style lang="scss">
@import "../../../assets/base.scss";
.companyAddress {
  text-align: left;
  width: 900px;
  margin-left: 150px;

  .addressTitle {
    text-align: left;
    font-size: 30px;
    color: $mainColor;
    margin: 10px 0 20px 0;
  }
  .form-input {
    margin-bottom: 15px;
    .form-input-title {
      font-size: 20px;
      color: $fontColor2;
      margin-bottom: 10px;
    }
    .cascader {
      height: 40px;
      width: 280px;
      .el-input__inner {
        height: 40px;
        width: 280px;
      }
    }
  }
  .unClick {
    color: #999;
    cursor: not-allowed;
  }
  .search {
    display: flex;
    justify-content: space-between;
  }
  .address {
    line-height: 16px;
  }
  .name {
    display: flex;
    .item {
      margin-right: 5px;
    }
    .partnerTag {
      background-color: $background2;
      color: #fff;
      font-size: 12px;
      padding: 0 5px;
      height: 14px;
      line-height: 14px;
      margin-left: 10px;
    }
  }
  .RoleRight {
    display: flex;
    justify-content: space-between;

    .tagList {
      display: flex;
      flex-wrap: wrap;
      width: 300px;

      .tag {
        border: 1px solid $mainColor;
        padding: 0 10px;
        height: 30px;
        line-height: 30px;
        margin-right: 5px;
        margin-bottom: 5px;
      }
    }
  }
  .signOut-icon1 {
    color: $fontColor2;
    font-size: 40px;
    cursor: pointer;
    text-align: right;
    padding-right: 10px;
    line-height: 30px;
  }
  .signOut-icon {
    color: $mainColor;
    width: 50px;
    height: 30px;
    text-align: right;
    background-color: $background1;
    border-top: 1px solid $borderColor1;
    border-left: 1px solid $borderColor1;
    border-right: 1px solid $borderColor1;
    z-index: 99;
    padding-right: 10px;
  }
  .signOutHover {
    position: absolute;
    right: 10px;
    top: 20px;
    height: 100px;
    width: 260px;
    z-index: 9;
    .list-button {
      display: flex;
      flex-wrap: wrap;
      position: absolute;
      right: 0;
      top: 20px;
      background-color: $background1;
      font-size: 20px;
      text-align: center;
      border: 1px solid $borderColor1;
      line-height: 30px;
      color: $mainColor;
      cursor: pointer;
      padding: 20px;
    }
    .list-button-item {
      padding: 0 5px;
      text-decoration: underline;
    }
  }
}
</style>