var listData = [{
    "summry": "Shanghai, CN -> Los Angeles",
    "type": "FCL",
    "status": "Awaiting Pricing",
    "GargoInfo": "40 ft * 2",
    "CargoReadyDay": "Dec 30th,2021",
    "Shipper": "Tianjin Woshang International Trade ",
    "Consignee": "BEST VT INC.",
    "CreateDate": "Dec 30th,2021",
    "bookingId": "QOF21110001",
    "Amount": "12000.00"
}, {
    "summry": "Shanghai, CN -> Los Angeles",
    "type": "LCL",
    "status": "Awaiting Pricing",
    "GargoInfo": "40 ft * 2",
    "CargoReadyDay": "Dec 30th,2021",
    "Shipper": "Tianjin Woshang International Trade ",
    "Consignee": "BEST VT INC.",
    "CreateDate": "Dec 30th,2021",
    "shipmentNo": "QOF21110001",
}, {
    "summry": "Shanghai, CN -> Los Angeles",
    "type": "FCL",
    "status": "Awaiting Pricing",
    "GargoInfo": "40 ft * 2",
    "CargoReadyDay": "Dec 30th,2021",
    "Shipper": "Tianjin Woshang International Trade ",
    "Consignee": "BEST VT INC.",
    "CreateDate": "Dec 30th,2021"
}, {
    "summry": "Shanghai, CN -> Los Angeles",
    "type": "FCL",
    "status": "Awaiting Pricing",
    "GargoInfo": "40 ft * 2",
    "CargoReadyDay": "Dec 30th,2021",
    "Shipper": "Tianjin Woshang International Trade ",
    "Consignee": "BEST VT INC.",
    "CreateDate": "Dec 30th,2021"
}, {
    "summry": "Shanghai, CN -> Los Angeles",
    "type": "FCL",
    "status": "Awaiting Pricing",
    "GargoInfo": "40 ft * 2",
    "CargoReadyDay": "Dec 30th,2021",
    "Shipper": "Tianjin Woshang International Trade ",
    "Consignee": "BEST VT INC.",
    "CreateDate": "Dec 30th,2021"
}, {
    "summry": "Shanghai, CN -> Los Angeles",
    "type": "FCL",
    "status": "Awaiting Pricing",
    "GargoInfo": "40 ft * 2",
    "CargoReadyDay": "Dec 30th,2021",
    "Shipper": "Tianjin Woshang International Trade ",
    "Consignee": "BEST VT INC.",
    "CreateDate": "Dec 30th,2021"
}, {
    "summry": "Shanghai, CN -> Los Angeles",
    "type": "FCL",
    "status": "Awaiting Pricing",
    "GargoInfo": "40 ft * 2",
    "CargoReadyDay": "Dec 30th,2021",
    "Shipper": "Tianjin Woshang International Trade ",
    "Consignee": "BEST VT INC.",
    "CreateDate": "Dec 30th,2021"
}, {
    "summry": "Shanghai, CN -> Los Angeles",
    "type": "FCL",
    "status": "Awaiting Pricing",
    "GargoInfo": "40 ft * 2",
    "CargoReadyDay": "Dec 30th,2021",
    "Shipper": "Tianjin Woshang International Trade ",
    "Consignee": "BEST VT INC.",
    "CreateDate": "Dec 30th,2021"
}, {
    "summry": "Shanghai, CN -> Los Angeles",
    "type": "FCL",
    "status": "Awaiting Pricing",
    "GargoInfo": "40 ft * 2",
    "CargoReadyDay": "Dec 30th,2021",
    "Shipper": "Tianjin Woshang International Trade ",
    "Consignee": "BEST VT INC.",
    "CreateDate": "Dec 30th,2021"
}, {
    "summry": "Shanghai, CN -> Los Angeles",
    "type": "FCL",
    "status": "Awaiting Pricing",
    "GargoInfo": "40 ft * 2",
    "CargoReadyDay": "Dec 30th,2021",
    "Shipper": "Tianjin Woshang International Trade ",
    "Consignee": "BEST VT INC.",
    "CreateDate": "Dec 30th,2021"
}, {
    "summry": "Shanghai, CN -> Los Angeles",
    "type": "FCL",
    "status": "Awaiting Pricing",
    "GargoInfo": "40 ft * 2",
    "CargoReadyDay": "Dec 30th,2021",
    "Shipper": "Tianjin Woshang International Trade ",
    "Consignee": "BEST VT INC.",
    "CreateDate": "Dec 30th,2021"
}, {
    "summry": "Shanghai, CN -> Los Angeles",
    "type": "FCL",
    "status": "Awaiting Pricing",
    "GargoInfo": "40 ft * 2",
    "CargoReadyDay": "Dec 30th,2021",
    "Shipper": "Tianjin Woshang International Trade ",
    "Consignee": "BEST VT INC.",
    "CreateDate": "Dec 30th,2021"
}, {
    "summry": "Shanghai, CN -> Los Angeles",
    "type": "FCL",
    "status": "Awaiting Pricing",
    "GargoInfo": "40 ft * 2",
    "CargoReadyDay": "Dec 30th,2021",
    "Shipper": "Tianjin Woshang International Trade ",
    "Consignee": "BEST VT INC.",
    "CreateDate": "Dec 30th,2021"
}]

var QuotationData = [{
    "ChargeItem": "Origin",
    "Price": "200.00",
    "Qty": "x 1",
    "Amount": "2000.00",
    "Cur": "RMB",
}, {
    "ChargeItem": "Freight",
    "Price": "5000.00",
    "Qty": "x 2",
    "Amount": "1000.00",
    "Cur": "USD",
}, {
    "ChargeItem": "Destination",
    "Price": "500.00",
    "Qty": "x 4",
    "Amount": "2000.00",
    "Cur": "RMB",
}]

var draftData = [{
    "id": "1",
    "DraftSummry": "Shanghai, CN -> Los Angeles, US 40 ft * 2",
    "Type": "FCL",
    "GargoInfo": "40 ft x 2",
    "LastUpdate": "Dec 30th,2021",
    "CreatedBy": "Grey Chen",
}, {
    "id": "2",
    "DraftSummry": "Shanghai, CN -> Los Angeles, US 40 ft x 2",
    "Type": "FCL",
    "GargoInfo": "40 ft x 2",
    "LastUpdate": "Dec 30th,2021",
    "CreatedBy": "Grey Chen",
}, {
    "id": "3",
    "DraftSummry": "Shanghai, CN -> Los Angeles, US 40 ft x 2",
    "Type": "FCL",
    "GargoInfo": "40 ft x 2",
    "LastUpdate": "Dec 30th,2021",
    "CreatedBy": "Grey Chen",
}, {

}, {

}]

var templateData = [{
    "id": "1",
    "name": "Template name",
    "Summry": "Shanghai, CN -> Los Angeles, US 40 ft x 2",
    "Type": "FCL",
    "Shipper": "Tianjin Woshang International Trade ...",
    "Consignee": "40 ft x 2",
    "LastUpdate": "Dec 30th,2021",
    "CreatedBy": "Grey Chen",
}, {
    "id": "2",
    "name": "Template name",
    "Summry": "Shanghai, CN -> Los Angeles, US 40 ft x 2",
    "Type": "FCL",
    "Shipper": "Tianjin Woshang International Trade ...",
    "Consignee": "40 ft x 2",
    "LastUpdate": "Dec 30th,2021",
    "CreatedBy": "Grey Chen",
}, {
    "id": "3",
    "name": "Template name",
    "Summry": "Shanghai, CN -> Los Angeles, US 40 ft x 2",
    "Type": "FCL",
    "Shipper": "Tianjin Woshang International Trade ...",
    "Consignee": "40 ft x 2",
    "LastUpdate": "Dec 30th,2021",
    "CreatedBy": "Grey Chen",
}, {

}, {

}, {

}, {

}, {

}, {

}, {

}]

var taskData = [{
    "id": "1",
    "Type": "Q",
    "TID": "111111111",
    "Summry": "Shanghai, CN -> Los Angeles, USOcean freight - FCL",
    "QID": "222222222",
    "Create": "Dec 30th,2021",
    "LastUpdate": "Dec 30th,2021",
    "Status": "UserSubmit",
}, {
    "id": "1",
    "Type": "Q",
    "TID": "111111111",
    "Summry": "Shanghai, CN -> Los Angeles, USOcean freight - FCL",
    "QID": "222222222",
    "Create": "Dec 30th,2021",
    "LastUpdate": "Dec 30th,2021",
    "Status": "UserSubmit",
}, {
    "id": "1",
    "Type": "B",
    "TID": "111111111",
    "Summry": "Shanghai, CN -> Los Angeles, USOcean freight - FCL",
    "QID": "222222222",
    "BID": "333333333",
    "Create": "Dec 30th,2021",
    "LastUpdate": "Dec 30th,2021",
    "Status": "UserSubmit",
}, {
    "id": "1",
    "Type": "B",
    "TID": "111111111",
    "Summry": "Shanghai, CN -> Los Angeles, USOcean freight - FCL",
    "QID": "222222222",
    "BID": "333333333",
    "Create": "Dec 30th,2021",
    "LastUpdate": "Dec 30th,2021",
    "Status": "UserSubmit",
}, {
    "id": "1",
    "Type": "B",
    "TID": "111111111",
    "Summry": "Shanghai, CN -> Los Angeles, USOcean freight - FCL",
    "QID": "222222222",
    "BID": "333333333",
    "Create": "Dec 30th,2021",
    "LastUpdate": "Dec 30th,2021",
    "Status": "UserSubmit",
}, {
    "id": "1",
    "Type": "B",
    "TID": "111111111",
    "Summry": "Shanghai, CN -> Los Angeles, USOcean freight - FCL",
    "QID": "222222222",
    "BID": "333333333",
    "Create": "Dec 30th,2021",
    "LastUpdate": "Dec 30th,2021",
    "Status": "UserSubmit",
}, {
    "id": "1",
    "Type": "B",
    "TID": "111111111",
    "Summry": "Shanghai, CN -> Los Angeles, USOcean freight - FCL",
    "QID": "222222222",
    "BID": "333333333",
    "Create": "Dec 30th,2021",
    "LastUpdate": "Dec 30th,2021",
    "Status": "UserSubmit",
}, {
    "id": "1",
    "Type": "B",
    "TID": "111111111",
    "Summry": "Shanghai, CN -> Los Angeles, USOcean freight - FCL",
    "QID": "222222222",
    "BID": "333333333",
    "Create": "Dec 30th,2021",
    "LastUpdate": "Dec 30th,2021",
    "Status": "UserSubmit",
}, {
    "id": "1",
    "Type": "B",
    "TID": "111111111",
    "Summry": "Shanghai, CN -> Los Angeles, USOcean freight - FCL",
    "QID": "222222222",
    "BID": "333333333",
    "Create": "Dec 30th,2021",
    "LastUpdate": "Dec 30th,2021",
    "Status": "UserSubmit",
}, {
    "id": "1",
    "Type": "B",
    "TID": "111111111",
    "Summry": "Shanghai, CN -> Los Angeles, USOcean freight - FCL",
    "QID": "222222222",
    "BID": "333333333",
    "Create": "Dec 30th,2021",
    "LastUpdate": "Dec 30th,2021",
    "Status": "UserSubmit",
}]

var staffData = [{
    "id": "grey@de-well.com",
    "Name": "Grey Chen",
    "Role": "Admin",
}, {
    "id": "grey@de-well.com",
    "Name": "Grey Chen",
    "Role": "Admin",
}, {
    "id": "grey@de-well.com",
    "Name": "Grey Chen",
    "Role": "Admin",
}, {
    "id": "grey@de-well.com",
    "Name": "Grey Chen",
    "Role": "Admin",
}]

var notificationnList = [{
    "id": "1",
    "Type": "B",
    "Subject": "Shanghai, CN -> Los Angeles, USOcean freight - FCL",
    "CreateTime": "Dec 30th,2021 10:20 am",
    "Sendto": "Grey Chen",
    "Sendby": "System",
}, {
    "id": "1",
    "Type": "Q",
    "Subject": "Shanghai, CN -> Los Angeles, USOcean freight - FCL",
    "CreateTime": "Dec 30th,2021 10:20 am",
    "Sendto": "Grey Chen",
    "Sendby": "DW Himonny",
}, {
    "id": "1",
    "Type": "S",
    "Subject": "Shanghai, CN -> Los Angeles, USOcean freight - FCL",
    "CreateTime": "Dec 30th,2021 10:20 am",
    "Sendto": "Grey Chen",
    "Sendby": "DW Terry",
}]

var addressData = [{
    "name": "Grey Chen",
    "phone": "18616863030",
    "address": "No. 525 Rongchuang center, Zhongshan nan lu, Shanghai, China",
    "tag": ['Invoice', 'Office']
}, {
    "name": "Grey Chen",
    "phone": "18616863030",
    "address": "No. 525 Rongchuang center, Zhongshan nan lu, Shanghai, China",
    "tag": ['Bill of lading']
}, {
    "name": "Grey Chen",
    "phone": "18616863030",
    "address": "No. 525 Rongchuang center, Zhongshan nan lu, Shanghai, China",
    "tag": ['Consignee']
}, {
    "name": "Grey Chen",
    "phone": "18616863030",
    "address": "No. 525 Rongchuang center, Zhongshan nan lu, Shanghai, China",
    "tag": ['Shipper']
}, {
    "name": "Grey Chen",
    "phone": "18616863030",
    "address": "No. 525 Rongchuang center, Zhongshan nan lu, Shanghai, China",
    "tag": ['Pick up']
}]

var trackingList = [{
    title: "Empty returned",
    address: "Long Beach,US",
    time: "Jan 15th, 2021",
    type: "1"
}, {
    title: "Gated out delivery full",
    address: "Long Beach,US",
    time: "Jan 15th, 2021",
    type: "2"
}, {
    title: "Freight Released",
    address: "Long Beach,US",
    time: "Jan 15th, 2021",
}, {
    title: "Ready for pick up",
    address: "Long Beach,US",
    time: "Jan 15th, 2021",
}, {
    title: "Gated out delivery full",
    address: "Long Beach,US",
    time: "Jan 15th, 2021",
    type: "3"
}, {
    title: "Freight Released",
    address: "Long Beach,US",
    time: "Jan 15th, 2021",
}, {
    title: "Discharged",
    address: "Long Beach,US",
    time: "Jan 15th, 2021",
}, {
    title: "Arrived discharge port",
    address: "Long Beach,US",
    time: "Jan 15th, 2021",
}, {
    title: "Customs Released",
    address: "Long Beach,US",
    time: "Jan 15th, 2021",
    type: "2"
}, {
    title: "Departed origin port",
    address: "Long Beach,US",
    time: "Jan 15th, 2021",
    type: "3"
}, {
    title: "Loaded full",
    address: "Qingdao, CN",
    time: "Jan 15th, 2021",
}, {
    title: "Gated in full",
    address: "Qingdao, CN",
    time: "Jan 15th, 2021",
    type: "2"
}, {
    title: "Empty container released",
    address: "Qingdao, CN",
    time: "Jan 15th, 2021",
    type: "3"
}];

const settingList = [
    {
        name: "Quotation",
        link: "/Quotation",
        second_level: [
            {
                name: "Get a quote",
                link: "/Quotation",
                icon: "quote",
            },
            {
                name: "My quotation",
                link: "/Quotation/quotation",
                icon: "quotation",
            },
            {
                name: "Template",
                link: "/Quotation/template",
                icon: "template",
            },
        ],
    },
    {
        name: "Booking",
        link: "/Booking",
    },
    {
        name: "Tracking",
        link: "/Tracking",
    },

    {
        name: "Task",
        link: "/Task",
    },

    {
        name: "Notification",
        link: "/Notification",
    },
    {
        name: "Settings",
        link: "",
        second_level: [
            {
                name: "Account",
                second_level: [
                    {
                        name: "Home",
                        link: "/Settings",
                        icon: "userHead",
                    },
                    {
                        name: "Personal information",
                        link: "/Settings/information",
                        icon: "personal",
                    },
                    {
                        name: "Security",
                        link: "/Settings/Security",
                        icon: "security",
                    },
                    {
                        name: "Data and privacy",
                        link: "/Settings/Privacy",
                        icon: "privacy",
                    },
                ],
            },
            {
                name: "Company",
                link: "/Settings/Company",
                second_level: [
                    {
                        name: "Company info",
                        link: "/Settings/Company",
                        icon: "Company",
                    },
                    {
                        name: "Contactor",
                        link: "/Settings/Contactor",
                        icon: "Contactor",
                    },
                    {
                        name: "Finance",
                        link: "/Settings/Finance",
                        icon: "Finance",
                    },
                ],
            },
            {
                name: "Address",
                second_level: [
                    {
                        name: "Company address",
                        link: "/Settings/CompanyAddress",
                        icon: "location",
                    },
                    {
                        name: "Partner address",
                        link: "/Settings/PartnerAddress",
                        icon: "location",
                    },
                    {
                        name: "Address tag",
                        link: "/Settings/AddressTag",
                        icon: "tag",
                    },
                ],
            },
            {
                name: "Staff",
                link: "/Settings/Staff",
            },
            {
                name: "Partner",
                link: "/Settings/Partner",
            },
            {
                name: "Roles",
                link: "/Settings/Roles",
            },
            {
                name: "Notification",
                link: "/Settings/Notification",
            },
        ],
    }
]

export default {
    listData,
    QuotationData,
    draftData,
    templateData,
    taskData,
    staffData,
    notificationnList,
    addressData,
    trackingList
}