<template>
  <div class="editInfo" v-loading="loading">
    <el-form :model="chooseData" :rules="rules" ref="addressForm">
      <div class="dw-card2" v-if="position == 'Partner'">
        <div class="title">{{ $t("lang.SText49") }}</div>
        <div class="main">
          <div class="form-line">
            <el-form-item
              class="form-item"
              prop="partnerId"
              :label="$t('lang.SText49')"
            >
              <el-cascader
                :options="partnerList"
                class="cascader"
                v-model="chooseData.partnerId"
                :show-all-levels="false"
                :placeholder="$t('lang.BText80')"
                @change="partnerChange"
                ><template slot-scope="{ node, data }">
                  <div class="line1">{{ data.label }}</div>
                  <div v-if="data.email" class="line2">{{ data.email }}</div>
                </template>
              </el-cascader>
            </el-form-item>
          </div>
        </div>
      </div>
      <div class="dw-card2">
        <div class="title">{{ $t("lang.SText50") }}</div>
        <div class="main">
          <div class="form-line">
            <el-form-item
              class="form-item"
              prop="addressTag"
              :label="$t('lang.SText39')"
            >
              <el-cascader
                :options="tagSelectOption"
                :props="props"
                collapse-tags
                class="cascader"
                :placeholder="$t('lang.BText80')"
                v-model="chooseData.addressTag"
                :show-all-levels="false"
                @change="changeTags"
                ><template slot-scope="{ node, data }">
                  <div class="line1">{{ data.label }}</div>
                  <div v-if="data.email" class="line2">{{ data.email }}</div>
                </template>
              </el-cascader>
            </el-form-item>
            <div class="tagList">
              <el-tag
                v-for="(tag, index) in addressTagList"
                :key="tag.value"
                closable
                @close="closaTag(index, tag)"
              >
                {{ tag.label }}
              </el-tag>
            </div>
          </div>
        </div>
      </div>
      <div class="dw-card2">
        <div class="title">{{ $t("lang.SText51") }}</div>
        <div class="main">
          <div class="form-line">
            <el-form-item
              class="form-item"
              prop="name"
              :label="$t('lang.SText52')"
            >
              <el-input v-model="chooseData.name"></el-input>
            </el-form-item>
          </div>
          <el-form-item
            class="form-item"
            prop="phone"
            :label="$t('lang.SText53')"
          >
            <div class="form-input">
              <el-select
                class="step1-select"
                v-model="chooseData.dialCode"
                filterable
                placeholder=""
                remote
                :remote-method="findPhoneList"
                :loading="selectLoading"
                @visible-change="visibleChange"
                clearable
              >
                <el-option
                  v-for="item in dialCodeList"
                  :key="item.iso2"
                  :label="item.iso2"
                  :value="item.iso2"
                >
                  <div class="phoneOption">
                    {{ item.iso2 + " | " + item.cname }}
                  </div>
                </el-option>
              </el-select>
              <el-input
                class="phoneInput"
                v-model="chooseData.phone"
                maxlength="11"
                type="number"
                @keydown.native="limite"
              >
              </el-input>
            </div>
          </el-form-item>
        </div>
      </div>
      <div class="dw-card2">
        <div class="title">{{ $t("lang.QText50") }}</div>
        <div class="main">
          <el-form-item
            class="form-item"
            prop="address"
            :label="$t('lang.QText50')"
          >
            <el-input
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 4 }"
              :placeholder="$t('lang.BText41')"
              v-model="chooseData.address"
              @input="addressInput"
            >
            </el-input>
          </el-form-item>
          <div class="form-line">
            <el-form-item
              class="form-item"
              prop="countryOrRegion"
              :label="$t('lang.QText52')"
            >
              <el-select
                class="date-card-select"
                v-model="chooseData.countryOrRegion"
                :placeholder="$t('lang.BText80')"
                @change="countryChange"
                ref="countryOrRegion"
                filterable
              >
                <el-option
                  v-for="item in countryList"
                  :key="item.code"
                  :label="item.name"
                  :value="item.code"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item
              class="form-item"
              prop="state"
              :label="$t('lang.QText53')"
            >
              <el-select
                class="date-card-select"
                v-model="chooseData.state"
                :placeholder="$t('lang.BText80')"
                @change="stateChange"
                ref="state"
                filterable
              >
                <el-option
                  v-for="item in stateList"
                  :key="item.code"
                  :label="item.name"
                  :value="item.code"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="form-line">
            <el-form-item
              class="form-item"
              prop="city"
              :label="$t('lang.QText54')"
            >
              <el-select
                class="date-card-select"
                v-model="chooseData.city"
                :placeholder="$t('lang.BText80')"
                @change="cityChange"
                filterable
                ref="city"
              >
                <el-option
                  v-for="item in cityList"
                  :key="item.code"
                  :label="item.name"
                  :value="item.code"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              class="form-item"
              prop="zipCode"
              :label="$t('lang.QText55')"
            >
              <el-input v-model="chooseData.zipCode" @input="zipCodeInput">
              </el-input>
            </el-form-item>
          </div>
          <el-form-item
            class="form-item"
            prop="billOfLadingFormat"
            :label="$t('lang.SText54')"
            v-show="showBill"
          >
            <el-input
              type="textarea"
              :autosize="{ minRows: 4, maxRows: 6 }"
              :placeholder="$t('lang.BText41')"
              v-model="chooseData.billOfLadingFormat"
              resize="none"
            >
            </el-input>
          </el-form-item>
        </div>
      </div>
      <div class="bottom-button">
        <el-button type="primary" :loading="saveLoading" @click="Save">{{
          $t("lang.SText13")
        }}</el-button>
        <el-button type="info" @click="Cancel">{{
          $t("lang.QText79")
        }}</el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    // var validatePhone = (rule, value, callback) => {
    //   if (value === "") {
    //     callback(new Error("please input phone"));
    //   } else {
    //     if (!/^1[3456789]\d{9}$/.test(value)) {
    //       callback(new Error("Please enter the correct phone number"));
    //     } else {
    //       callback();
    //     }
    //   }
    // };
    return {
      form: {},
      props: { multiple: true },
      tagList: [],
      loading: false,
      addressTagList: [],
      countryList: [],
      cityList: [],
      stateList: [],
      dialCodeList: [],
      selectLoading: false,
      userInfo: {},
      partnerList: [],
      saveLoading: false,
      showBill: false,
      billofladingformatInput: "",
    };
  },
  props: {
    chooseData: {
      type: Object,
      default: function () {
        return {};
      },
    },
    position: {
      type: String,
    },
    tagSelectOption: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  computed: {
    rules: function () {
      return {
        partnerId: [
          {
            required: true,
            message: this.$t("lang.SText152"),
            trigger: "change",
          },
        ],
        addressTag: [
          {
            required: true,
            message: this.$t("lang.SText153"),
            trigger: "change",
          },
        ],
        name: [
          // { required: true, message: "Please input name"},
          { min: 1, max: 20, message: this.$t("lang.SText154") },
        ],
        phone: [
          // { required: true, message: "Please input phone"},
          { max: 20, message: this.$t("lang.SText154") },
          {
            trigger: "blur",
            validator: (rule, value, callback) => {
              if (this.chooseData.dialCode == "86") {
                if (!/^1[3456789]\d{9}$/.test(value)) {
                  callback(new Error(this.$t("lang.SText156")));
                } else {
                  callback();
                }
              } else if (this.chooseData.dialCode == "1") {
                if (!/^[2-9]\d{2}[2-9](?!11)\d{6}$/.test(value)) {
                  callback(new Error(this.$t("lang.SText156")));
                } else {
                  callback();
                }
              } else {
                callback();
              }
            },
          },
        ],
        address: [
          {
            required: true,
            message: this.$t("lang.SText157"),
          },
          { max: 150, message: this.$t("lang.SText158") },
          // {
          //   validator: (rule, value, callback) => {
          //     if (value.indexOf(" ") > 0) {
          //       callback(new Error("不可有空格"));
          //     } else {
          //       callback();
          //     }
          //   },
          // },
        ],
        city: [
          {
            required: true,
            message: this.$t("lang.SText159"),
            trigger: "change",
          },
        ],
        state: [
          {
            required: true,
            message: this.$t("lang.SText160"),
            trigger: "change",
          },
        ],
        countryOrRegion: [
          {
            required: true,
            message: this.$t("lang.SText161"),
            trigger: "change",
          },
        ],
        zipCode: [
          { max: 20, message: this.$t("lang.SText154") },
          {
            trigger: "blur",
            validator: (rule, value, callback) => {
              if (value) {
                var patrn = /^[0-9]*$/;
                if (!patrn.test(value)) {
                  callback(new Error(this.$t("lang.SText162")));
                } else {
                  callback();
                }
              } else {
                callback();
              }
            },
          },
        ],
        billOfLadingFormat: [
          { max: 150, message: this.$t("lang.SText158") },
          // {
          //   trigger: "blur",
          //   validator: (rule, value, callback) => {
          //     if (value) {
          //       if (value.indexOf(" ") > 0) {
          //         callback(new Error(this.$t("lang.SText163")));
          //       } else {
          //         callback();
          //       }
          //     } else {
          //       callback();
          //     }
          //   },
          // },
        ],
      };
    },
  },

  watch: {
    chooseData: function (newVal) {
      if (newVal.addressTag != undefined) {
        this.changeTags();
      } else {
        this.addressTagList = [];
      }
      if (newVal.countryOrRegion) {
        this.countryChange(newVal.countryOrRegion);
      }
      if (newVal.state) {
        this.stateChange(newVal.state);
      }
    },
  },
  created() {
    this.$session("countryList").get((value) => {
      this.countryList = value;
    });

    if (this.chooseData.countryOrRegion) {
      this.countryChange(this.chooseData.countryOrRegion);
    }
    if (this.chooseData.state) {
      this.stateChange(this.chooseData.state);
    }
    this.$session("userInfo").get((value) => {
      this.userInfo = value;
    });
    this.getParterList();
    this.findPhoneList();
    if (this.chooseData.addressTag != undefined) {
      this.changeTags();
    } else {
      this.addressTagList = [];
    }
  },
  methods: {
    addressInput(e) {
      this.$nextTick(function () {
        this.billofladingformatInput = e;
        var inputText =
          (this.billofladingformatInput ? this.billofladingformatInput : "") +
          " " +
          (this.chooseData.city ? this.$refs.city.selectedLabel : "") +
          " " +
          (this.chooseData.state ? this.$refs.state.selectedLabel : "") +
          " " +
          (this.chooseData.zipCode ? this.chooseData.zipCode : "") +
          " " +
          (this.chooseData.countryOrRegion
            ? this.$refs.countryOrRegion.selectedLabel
            : "");
        this.$set(this.chooseData, "billOfLadingFormat", inputText);
      });
    },
    clearValidate() {
      if (this.$refs["addressForm"]) {
        // 延时执行
        this.$nextTick(function () {
          this.$refs["addressForm"].clearValidate();
        });
      }
    },
    closaTag(index, tag) {
      this.addressTagList.splice(index, 1);
      let aa = false;
      this.addressTagList.forEach((item, index) => {
        if (item.label == "Shipper" || item.label == "Consignee") {
          aa = true;
        }
      });
      this.showBill = aa;
      let arr = [...[], ...this.chooseData.addressTag];
      arr.forEach((item, index2) => {
        if (item[1] == tag.value) {
          arr.splice(index2, 1);
        }
      });
      this.$set(this.chooseData, "addressTag", arr);
    },
    changeTags() {
      let arr = [...[], ...this.chooseData.addressTag];
      this.addressTagList = [];
      let arr1 = [
        ...[],
        ...this.tagSelectOption[0].children,
        ...this.tagSelectOption[1].children,
      ];

      arr1.forEach((option) => {
        arr.forEach((item) => {
          if (item[1] == option.value) {
            if (option.value == "Shipper" || option.value == "Consignee")
              this.showBill = true;
            this.addressTagList.push(option);
          }
        });
      });
    },
    partnerChange(e) {
      this.chooseData.partnerId = e[1];
    },
    Save() {
      let that = this;
      that.$refs["addressForm"].validate((valid) => {
        if (valid) {
          that.saveLoading = true;
          that.loading = true;
          let url = "";
          if (this.chooseData && this.chooseData.id) {
            url = that.source.address_api.editAddress;
          } else {
            url = that.source.address_api.addAddress;
          }
          let newAddressTag = {};
          that.chooseData.addressTag.forEach((item, index) => {
            if (!(item[0] in newAddressTag)) {
              newAddressTag[item[0]] = [];
            }
            newAddressTag[item[0]].push(item[1]);
          });
          that.chooseData.addressTag = JSON.stringify(newAddressTag);
          if (
            that.chooseData.dialCode &&
            that.chooseData.dialCode !== "" &&
            that.chooseData.phone &&
            that.chooseData.phone !== ""
          ) {
            that.chooseData.phone =
              that.chooseData.dialCode + " " + that.chooseData.phone;
          } else {
            that.chooseData.phone = "";
          }

          that.position == "Company"
            ? (that.chooseData.type = "company")
            : (that.chooseData.type = "partner");
          that.chooseData.companyId = that.userInfo.companyid;
          that.$ajax.cPost(url, that.chooseData).then((res) => {
            if (res.data.result == "true") {
              that.$notify.success({
                title: res.data.message,
                offset: 100,
              });
            } else {
              that.$notify.success({
                title: res.data.message,
                offset: 100,
              });
            }
            that.loading = false;
            this.$emit("SaveSuccess");
          });
        } else {
          console.log("error submit!!");
          return false;
        }
        that.saveLoading = true;
      });
    },
    Cancel() {
      this.$emit("Cancel");
    },
    getStateList(data) {
      let that = this;
      that.$ajax
        .cPost(that.source.address_api.getStateList, data)
        .then((res) => {
          if (res.data.result) {
            this.stateList = res.data.data;
          } else {
            this.$notify.error({
              title: res.message,
              offset: 100,
              duration: 3000,
            });
          }
        });
    },
    getCityList(data) {
      let that = this;
      that.$ajax
        .cPost(that.source.address_api.getCityList, data)
        .then((res) => {
          if (res.data.result) {
            this.cityList = res.data.data;
          } else {
            this.$notify.error({
              title: res.message,
              offset: 100,
              duration: 3000,
            });
          }
        });
    },
    countryChange(e) {
      this.form.state = "";
      if (e) {
        this.getStateList({ code: e });
      }
    },
    stateChange(e) {
      if (e) {
        this.getCityList({ state: e, code: this.chooseData.countryOrRegion });
      }
    },
    cityChange(e) {
      this.$nextTick(function () {
        var inputText =
          (this.billofladingformatInput ? this.billofladingformatInput : "") +
          " " +
          (this.chooseData.city ? this.$refs.city.selectedLabel : "") +
          " " +
          (this.chooseData.state ? this.$refs.state.selectedLabel : "") +
          " " +
          (this.chooseData.zipCode ? this.chooseData.zipCode : "") +
          " " +
          (this.chooseData.countryOrRegion
            ? this.$refs.countryOrRegion.selectedLabel
            : "");
        this.$set(this.chooseData, "billOfLadingFormat", inputText);
      });
    },
    zipCodeInput(e) {
      this.$nextTick(function () {
        var inputText =
          (this.billofladingformatInput ? this.billofladingformatInput : "") +
          " " +
          (this.chooseData.city ? this.$refs.city.selectedLabel : "") +
          " " +
          (this.chooseData.state ? this.$refs.state.selectedLabel : "") +
          " " +
          (this.chooseData.zipCode ? this.chooseData.zipCode : "") +
          " " +
          (this.chooseData.countryOrRegion
            ? this.$refs.countryOrRegion.selectedLabel
            : "");
        this.$set(this.chooseData, "billOfLadingFormat", inputText);
      });
    },
    visibleChange(e) {
      if (!e) {
        this.findPhoneList();
      }
    },
    findPhoneList(e) {
      this.selectLoading = true;
      this.$ajax
        .cPost(this.source.address_api.phone, {
          dialCode: e,
        })
        .then((res) => {
          this.selectLoading = false;
          if ((res.data.result = "true")) {
            this.dialCodeList = res.data.data;
          } else {
            this.$notify.error({
              title: res.data.message,
              offset: 100,
              duration: 3000,
            });
          }
        });
    },
    getParterList() {
      this.$ajax
        .cPost(this.source.address_api.parterSelect, {
          companyId: this.userInfo.companyid,
        })
        .then((res) => {
          if ((res.data.result = "true")) {
            this.partnerList = res.data.list;
          } else {
            this.$notify.error({
              title: res.data.message,
              offset: 100,
              duration: 3000,
            });
          }
        });
    },
    limite(e) {
      let key = e.key;
      if (key === "e" || key === ".") {
        e.returnValue = false;
        return false;
      }
      return true;
    },
  },
};
</script>

<style lang="scss">
.editInfo {
  width: 770px;
  margin: auto;
  padding-bottom: 40px;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
  .el-input__inner {
    height: 48px;
  }
  .form-line {
    display: flex;
    justify-content: space-between;
    .form-item {
      width: 48%;
      position: relative;
    }
  }
  .el-form-item {
    display: flex;
    flex-direction: column;
  }
  .bottom-button {
    margin: auto;
    width: 400px;
    .el-button {
      width: 180px;
      height: 64px;
    }
  }
  .step1-select {
    width: 100px;
  }
  .phoneInput {
    width: 240px;
  }
  .tagList {
    width: 500px;
  }
  .date-card-select {
    width: 100%;
  }
}
</style>